<template>
          <div class="row">
            <div class="col-12 ">
               <a ref="downloadLink" style="display: none;"></a>
                <ProductHeader  style="background:#f6f6f7"  :isFileDownload="isFileDownload" @export="exportCSV($event)" :loadSavedProducts="loadSavedProducts" :exportProductFilter="exportFileFilters" @AddNewProductView="ChangeView('AddProduct')" @ProductMatch="ChangeView('ProductMatch')" @getSelectedProduct="updateSelectedProduct($event)"/>
                <ProductTable :loadSavedProducts="loadSavedProducts"  @exportFile="exportFile" @AddNewProductView="ChangeView('AddProduct')" @ProductMatch="ChangeView('ProductMatch')" @getSelectedProduct="updateSelectedProduct($event)" ref="AllProducts"></ProductTable>
            </div>
          </div>
        
</template>

<script>
import axios from 'axios'
export default {
  components: {
    ProductHeader: () =>
      import(
        /* webpackChunkName: "ProductHeader" */ "./ProductHeader.vue"
      ),
      ProductTable: () =>
      import(
        /* webpackChunkName: "ProductTable" */ "./ProductTable.vue"
      ),
  },
data(){
  return{
    Token:'',
    isProductLoading:false,
    exportProductCategory:'all',
    view:'AllProducts',
    selectedProduct:{},
   
    isFileDownload:{uploadProgress:'0%',isLoading:false},
    exportFileFilters:{
        status: "",
        title: "",
        productIds: []
      }
  }
},

methods:{
 async exportCSV(category){
  this.isFileDownload.isLoading=true;
  let filter={
    file_type:'csv'
  }
  if(category=='filtered')
  {filter.status=this.exportFileFilters.status;
  filter.title=this.exportFileFilters.title}
else if(category=='selected')
{
  filter.productIds=this.exportFileFilters.productIds
}
     await axios.post('products/export',filter, {onUploadProgress: (progressEvent) => {
            this.isFileDownload.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }},).then(response=>{
       const blob = new Blob([response.data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const downloadLink = this.$refs.downloadLink;
          downloadLink.href = url;
          downloadLink.download = 'quizell-products.csv';
          downloadLink.click();
          window.URL.revokeObjectURL(url);
      this.isLoadingExport = false;
      this.$toasted.show("File has been downloaded.", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
     }).catch(()=>{
      this.$toasted.show("Error while downloading", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
     })
      this.isFileDownload.isLoading=false;
  },
 exportFile(filter)
{
 this.exportFileFilters = filter

},
  loadSavedProducts(str){
    this.$refs.AllProducts.loadSavedProducts(str)
  },
    Initial(){
      this.getProducts()
    },
    openShopifyModal(){
        this.$refs.shopifyProductModalRef.OpenModal()
    },

ChangeView(value){
this.view = value
this.selectedProduct = {}
},

updateSelectedProduct(product){
  if(product){
    this.selectedProduct = product
    this.view = 'EditProduct'

  }
}

},

}
</script>

<style scoped>
.Product-page{
  background:#f6f6f7;
  text-align: left;
  min-height:100vh;
}

.QuestionsDiv{
    height:100%;
}

[contenteditable] {
  outline: 0px solid transparent;
}
.ProductComp{
  padding: 20px 40px;
}

@media only screen and (max-width: 1200px) {
  .ProductComp{
  padding: 20px 10px;
}
}
</style>
